import {RelatedFeaturesGroup_RelatedFeatureRole as RelatedFeatureRole} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb';

/**
 * The default priority to use if one is not selected.
 */
export const DEFAULT_PRIORITY = 'P5 - Other';

// TODO(reubenn): Remove after upload priorities are saved in database.
/**
 * The default new priority to use if one is not selected.
 */
export const DEFAULT_NEW_PRIORITY = 'C5 - Other';

/**
 * The default work required to use if one is not selected.
 */
export const DEFAULT_WORK_REQUIRED = 'N/A';

/**
 * TOGOs mapped to their human-readable counterpart.
 * TODO: backfill and remove this. Note that this is not i18n compliant but as
 * it's only applicable to fields that were filled in previously (for US envs).
 */
export const DISPLAY_NAME_BY_TOGO = new Map([
  ['TOGOS_work_required', 'Work required'],
  ['TOGOS_priority', 'Priority'],
  ['TOGOS_formatted_address', 'Address'],
  ['TOGOS_feeder_id', 'Feeder ID'],
  ['TOGOS_location_description', 'Location description'],
  ['TOGOS_uploaded_on', 'Uploaded on'],
]);

/**
 *  The direction that the images are sorted by date. Most recent first.
 */
export const IMAGE_DATE_SORT_RECENT_FIRST = 'desc';

/**
 * List of roles that correspond to related image features.
 */
export const RELATED_IMAGE_FEATURE_ROLES = [
  RelatedFeatureRole.AUTOTOPOLOGY_CHILD_IMAGE,
  RelatedFeatureRole.CHILD_IMAGE,
];
