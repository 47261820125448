import {LatLng} from '@tapestry-energy/npm-prod/google/type/latlng_pb';
import {BehaviorSubject, Observable, ReplaySubject, Subject, of} from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {Clipboard} from '@angular/cdk/clipboard';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

import {LifecycleStage} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/common_pb';
import {Feature, Property} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/feature_pb';
import {Point} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/geometry_pb';
import {Image} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/image_pb';
import {Layer_LayerType} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/layer_pb';
import {
  RelatedFeature,
  RelatedFeaturesGroup_RelatedFeatureRole as RelatedFeatureRole,
  RelatedFeaturesGroup,
} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/related_feature_pb';
import {Tag} from '@tapestry-energy/npm-prod/tapestry/gridaware/api/v1/tag_pb';

import {LABEL_BY_RELATED_ROLE} from '../constants/common';
import {
  ASSETS_LAYER_ID,
  CIRCUITS_LAYER_NAME,
  DEFECTS_LAYER_ID,
  SUNROOF_LAYER_ID,
} from '../constants/layer';
import {
  FEATURE_PARAM_KEY,
  IMAGE_PARAM_KEY,
  LAYER_PARAM_KEY,
  QUERY_PARAMS,
  ROUTE,
} from '../constants/paths';
import {ThumbnailMetadata} from '../gallery/lightbox/lightbox';
import {AnalyticsService, EventActionType, EventCategoryType} from '../services/analytics_service';
import {ConfigService} from '../services/config_service';
import {FeaturesService} from '../services/features_service';
import {GoogleMapsService} from '../services/google_maps_service';
import {LayersService} from '../services/layers_service';
import {MapService} from '../services/map_service';
import {NetworkService} from '../services/network_service';
import {PhotosService, ensureURL} from '../services/photos_service';
import {TagsService} from '../services/tags_service';
import {UploadService} from '../services/upload_service';
import {StreetViewResponse} from '../typings/street_view';
import {buildRelatedFeatureString, groupRelatedFeaturesByLayerId} from '../utils/feature';
import {
  ImageUrlOptions,
  THUMBNAIL_SIZE_PX,
  convertGCSImageURL,
  getCurrentImageIndex,
  getNewImageIndex,
  isGCSImageUrl,
} from '../utils/image';
import {removeSensitiveProperties} from '../utils/properties';
import {sortImagesByUploadTime} from '../utils/sort';

interface UrlParams {
  layerId: string;
  featureId: string;
  imageId: string;
}

// Used in the template for displaying groups of related features.
interface RelatedFeatureDisplay {
  layerName: string;
  relatedFeatures: RelatedFeature[];
}

interface FeatureDisplayInfo {
  feature: Feature | null;
  id: string;
  name: string;
  isInactive: boolean;
  properties: Property[];
  lastUpdatedAt: Date | null;
  relatedFeatureGroups: RelatedFeaturesGroup[];
  // Related features grouped by layer name. This is used for displaying a list
  // of related features grouped by layer.
  relatedFeatureDisplays: RelatedFeatureDisplay[];
  tags: Set<string>;
  location: google.maps.LatLngLiteral | null;
  address: Observable<string | null>;
  // Helps differentiate display of point vs multi-point (e.g. regions) features.
  isPoint: boolean;
}

interface LayerDisplayInfo {
  id: string;
  name: string;
}

const MS_PER_SECOND = 1000;
const DEFAULT_RELATED_FEATURES_TITLE = 'Related features';
const OFFLINE_LAYER_NAME = 'Assets';
const EMPTY_FEATURE: FeatureDisplayInfo = {
  feature: null,
  id: '',
  name: '',
  isInactive: false,
  properties: [],
  lastUpdatedAt: null,
  relatedFeatureGroups: [],
  relatedFeatureDisplays: [],
  tags: new Set(),
  location: null,
  address: new BehaviorSubject(null),
  isPoint: false,
};

@Component({
  selector: 'feature-details',
  templateUrl: './feature_details.html',
  styleUrl: './feature_details.scss',
})

/**
 * Component for rendering Feature Details Page. Reads a layer key and feature
 * from the URL and displays the details of the feature.
 */
export class FeatureDetails implements OnInit, OnDestroy {
  private heroSatellite!: ElementRef<HTMLElement>;
  private streetViewContainer!: ElementRef<HTMLElement>;

  // Set satelliteContent will fire when the ViewChild enters the DOM. This is
  // required because the ViewChild is wrapped in an *ngIf Directive.
  @ViewChild('heroSatellite', {static: false})
  set satelliteContent(satelliteRef: ElementRef) {
    if (!satelliteRef || this.heroSatellite) return;
    this.heroSatellite = satelliteRef;
    if (this.streetViewContainer) {
      this.initSatelliteStreetViewListener();
    }
  }

  @ViewChild('streetViewContainer', {static: false})
  set streetViewContent(streetViewRef: ElementRef) {
    if (!streetViewRef || this.streetViewContainer) return;
    this.streetViewContainer = streetViewRef;
    if (this.heroSatellite) {
      this.initSatelliteStreetViewListener();
    }
  }

  // Function queue stores functions that will run after ViewChilds have entered
  // the DOM.
  private readonly satelliteStreetViewTasks = new ReplaySubject<Function>();
  private readonly destroyed = new Subject<void>();

  featureUpdates = new BehaviorSubject<Feature | null>(null);
  // *** Feature metadata display ***
  featureInfo: FeatureDisplayInfo = EMPTY_FEATURE;

  // *** Layer metadata display ***
  layerInfo: LayerDisplayInfo = {
    id: '',
    name: '',
  };

  // *** Image display ***
  image: Image | null = null;
  // A feature's images if they exist.
  images: Image[] = [];
  imageId: string = '';
  // The index of the feature's image which is being displayed.
  currentImageIndex = 0;
  // Specify a thumbnail size slightly larger than the thumbnail height that
  // balances quality versus image load time.
  thumbnailUrlOptions: ImageUrlOptions = {
    height: THUMBNAIL_SIZE_PX,
    width: THUMBNAIL_SIZE_PX,
  };
  thumbnailMetadata: ThumbnailMetadata | null = null;
  annotationCountByImageId = new Map<string, number>();

  // *** Flags and feature toggles ***
  // Show Solar insights if feature flag enabled and viewing a circuit.
  // TODO(b/323415506): Remove old flag.
  showSolarInsights = false;
  // Display tags and allow them to be added for this layer. Currently, tags are
  // only allowed on the defect layer.
  tagsEnabled = false;
  // Display upload button. Currently, only allowed for asset layer.
  uploadsEnabled = false;
  // Display edit icon. Currently, edits are only allowed for the defect layer
  // if upload view is enabled.
  editsEnabled = false;
  commentFormVisible = false;
  isInspectionStatusVisible = false;
  isOffline = false;
  loading = false;

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly changeDetectionRef: ChangeDetectorRef,
    private readonly clipboard: Clipboard,
    private readonly configService: ConfigService,
    private readonly featuresService: FeaturesService,
    private readonly googleMapsService: GoogleMapsService,
    private readonly layersService: LayersService,
    private readonly mapService: MapService,
    private readonly networkService: NetworkService,
    private readonly photosService: PhotosService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly snackBar: MatSnackBar,
    private readonly tagsService: TagsService,
    private readonly uploadService: UploadService,
  ) {}

  ngOnInit() {
    this.showSolarInsights = this.configService.solarEnabled;
    this.layersService.getAllLayersMetadata().pipe(take(1), takeUntil(this.destroyed)).subscribe();
    this.getOfflineStatus();
    this.route.paramMap
      .pipe(
        distinctUntilChanged(),
        map((paramMap: ParamMap) => ({
          layerId: paramMap.get(LAYER_PARAM_KEY) as string,
          featureId: paramMap.get(FEATURE_PARAM_KEY) as string,
          imageId: paramMap.get(IMAGE_PARAM_KEY) as string,
        })),
        tap(({layerId, imageId}: UrlParams) => {
          const layerName = this.isOffline
            ? OFFLINE_LAYER_NAME
            : this.layersService.getLayerName(layerId) || '';
          this.layerInfo = {
            id: layerId,
            name: layerName,
          };
          this.imageId = imageId;
          this.setSolarInsights();
        }),
        tap(() => {
          this.hideStreetview();
        }),
        switchMap(({layerId, featureId}: UrlParams) => this.getFeature(layerId, featureId)),
        takeUntil(this.destroyed),
      )
      .subscribe((feature: Feature | null) => {
        this.featureUpdates.next(feature);
        this.setFeatureDisplay(feature);
      });

    // Fetch images with metadata.
    this.route.paramMap
      .pipe(
        distinctUntilChanged(),
        map((paramMap: ParamMap) => paramMap.get(FEATURE_PARAM_KEY) as string),
        filter((featureId) => !!featureId),
        tap(() => {
          this.loading = true;
          this.thumbnailMetadata = null;
          this.images = [];
        }),
        switchMap((featureId: string) => this.photosService.getFeatureImages(featureId)),
        tap((images: Image[]) => {
          this.loading = false;
          // Update image display info, omitting the annotations count.
          this.images = images.length ? sortImagesByUploadTime(images) : [];
          if (!this.images.length) {
            return;
          }
          this.currentImageIndex =
            this.imageId === null ? 0 : getCurrentImageIndex(this.images, this.imageId);
          this.thumbnailMetadata = {
            totalImageCount: this.images.length,
            selectedImageIndex: this.currentImageIndex,
            annotationCount: 0,
          };
          this.changeDetectionRef.detectChanges();
        }),
        switchMap((images: Image[]) => this.fetchImageAnnotationCounts(images)),
        takeUntil(this.destroyed),
      )
      .subscribe((annotationCountByImageId: Map<string, number>) => {
        this.annotationCountByImageId = annotationCountByImageId;
        // Update the current thumbnail with the annotation count.
        const imageId = this.images[this.currentImageIndex]?.id || '';
        if (!this.annotationCountByImageId.has(imageId)) {
          if (imageId) {
            console.error(
              `Annotation count of current image with ID ${imageId} not found: annotationCountByImageId ${this.annotationCountByImageId}`,
            );
          }
          return;
        }
        if (this.thumbnailMetadata) {
          this.thumbnailMetadata.annotationCount = this.annotationCountByImageId.get(imageId)!;
        }
        this.changeDetectionRef.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
    this.googleMapsService.destroy();
  }

  doUpdateTags(tagNames: Set<string>) {
    const tags = [...tagNames].map((t: string): Tag => new Tag({name: t}));
    const id = this.featureInfo.id;
    this.featuresService
      .updateTags(this.layerInfo.id, id, tags)
      .pipe(
        mergeMap(() => this.tagsService.getTags(this.layerInfo.id, true)),
        take(1),
        takeUntil(this.destroyed),
      )
      .subscribe(() => {
        this.snackBar.open('Tags updated.', '', {duration: 2500});
      });
  }

  /**
   * Copies a feature's name to clipboard.
   */
  copyFeatureName() {
    if (!this.featureInfo.name) {
      // TODO(reubenn): Add some type of debug-time logging.
      return;
    }
    this.clipboard.copy(this.featureInfo.name);
    this.snackBar.open(`${this.featureInfo.name} copied to clipboard.`, '', {
      duration: 2500,
    });
  }

  shouldEnableInspectionStatus(): boolean {
    return !this.isOffline && this.layerInfo.id === DEFECTS_LAYER_ID;
  }

  roleTitle(role: RelatedFeatureRole): string {
    return LABEL_BY_RELATED_ROLE.get(role) || DEFAULT_RELATED_FEATURES_TITLE;
  }

  relatedFeatureString(related: RelatedFeature, role: RelatedFeatureRole) {
    return buildRelatedFeatureString(related, role);
  }

  editDefect() {
    if (this.configService.uploadFormImprovementsEnabled) {
      this.uploadService.renderUploadDialog({
        featureId: this.featureInfo.id,
        layerId: this.layerInfo.id,
        edit: true,
      });
    } else {
      this.router.navigate([ROUTE.PHOTO_UPLOAD], {
        queryParams: {
          [QUERY_PARAMS.FEATURE_ID]: this.featureInfo.id,
          [QUERY_PARAMS.LAYER_ID]: this.layerInfo.id,
          [QUERY_PARAMS.EDIT]: true,
          [QUERY_PARAMS.SOURCE_URL]: this.router.url,
        },
      });
    }
  }

  openLightbox() {
    this.analyticsService.sendEvent(EventActionType.LIGHTBOX_OPEN, {
      event_category: EventCategoryType.IMAGE,
      event_label: 'Feature page', // From whence the lightbox was opened.
    });
    const imageId = this.getImageId();
    this.router.navigate([ROUTE.LIGHTBOX], {
      queryParams: {
        [QUERY_PARAMS.LAYER_ID]: this.layerInfo.id,
        [QUERY_PARAMS.FEATURE_ID]: this.featureInfo.id,
        [QUERY_PARAMS.IMAGE_ID]: imageId,
        [QUERY_PARAMS.SOURCE_URL]: this.router.url,
      },
    });
  }

  editImage() {
    const queryParams: {[key: string]: string | boolean} = {
      [QUERY_PARAMS.IMAGE_ID]: this.getImageId(),
      [QUERY_PARAMS.SOURCE_URL]: this.router.url,
      [QUERY_PARAMS.LAYER_ID]: this.layerInfo.id,
      [QUERY_PARAMS.FEATURE_ID]: this.featureInfo.id,
      [QUERY_PARAMS.EDIT]: true,
      [QUERY_PARAMS.RETURN_ON_EXIT]: true,
    };
    this.router.navigate([ROUTE.LIGHTBOX], {queryParams});
  }

  goToMap() {
    this.router.navigateByUrl(ROUTE.MAP);
  }

  goToRelatedFeature(feature: RelatedFeature) {
    this.mapService.setShouldRepositionMap(true);
    this.router.navigate([ROUTE.MAP, feature.layerId, feature.id]);
  }

  goToImageUpload() {
    if (this.configService.uploadFormImprovementsEnabled) {
      this.uploadService.renderUploadDialog({
        // Pass the internal GA ID to pull all the feature details
        // from backend in online scenarios.
        assetId: this.featureInfo.id,
        // Also pass the external ID to associate
        // the upload in offline scenarios.
        externalId: this.featureInfo.feature?.externalId || '',
      });
    } else {
      const queryParams =
        // Pass the internal GA ID to pull all the feature details
        // from backend in online scenarios.
        {
          [QUERY_PARAMS.ASSET_ID]: this.featureInfo.id,
          // Also pass the external ID to associate
          // the upload in offline scenarios.
          [QUERY_PARAMS.EXT_ASSET_ID]: this.featureInfo.feature?.externalId || '',
          [QUERY_PARAMS.SOURCE_URL]: this.router.url,
        };
      this.router.navigate([ROUTE.PHOTO_UPLOAD], {queryParams});
    }
  }

  selectImage(direction: 'next' | 'prev') {
    const index = getNewImageIndex(this.currentImageIndex, this.images.length, direction);
    if (index === -1) {
      console.error(`selectImage(${direction}): failed to return new image index.`);
      return;
    }
    this.currentImageIndex = index;
    // Update the current thumbnail with the annotation count.
    const imageId = this.images[this.currentImageIndex].id;
    this.thumbnailMetadata = {
      ...this.thumbnailMetadata!,
      selectedImageIndex: this.currentImageIndex,
      annotationCount: this.annotationCountByImageId.get(imageId)!,
    };
  }

  private setFeatureDisplay(feature: Feature | null) {
    // Filter out child image relations. Images are part of image
    // groups which are their own related feature group.
    const relatedFeatureGroups = feature
      ? feature.relatedFeaturesGroups.filter(
          (group: RelatedFeaturesGroup) => group.role !== RelatedFeatureRole.CHILD_IMAGE,
        )
      : [];
    const relatedFeaturesByLayerId = groupRelatedFeaturesByLayerId(relatedFeatureGroups);
    const relatedFeatureDisplays = [];
    for (const [layerId, relatedFeatures] of relatedFeaturesByLayerId) {
      relatedFeatureDisplays.push({
        layerName: this.layersService.getLayerName(layerId) || '',
        relatedFeatures,
      });
    }
    const isPointFeature = feature?.geometry?.geometry?.case === 'point';
    const location = isPointFeature ? this.getFeatureLocation(feature) : null;
    const address = location ? this.googleMapsService.getAddressFromLatLng(location) : of('');

    this.featureInfo = {
      feature,
      id: feature?.id || '',
      name: feature?.name || '',
      isInactive: feature?.lifecycleStage === LifecycleStage.INACTIVE,
      properties: removeSensitiveProperties(feature?.properties || []),
      lastUpdatedAt: feature ? this.getLastUpdatedAt(feature) : null,
      relatedFeatureGroups,
      relatedFeatureDisplays,
      tags: new Set((feature?.tags || []).map((tag: Tag): string => tag.name)),
      location,
      address,
      isPoint: isPointFeature,
    };
    if (!this.layerInfo.id || !feature?.id) {
      this.goToMap();
      return;
    }

    this.setActions();
    this.setHeaderImage(feature);

    // For offline features skip the geolocation and satellite
    // configuration.
    if (!this.isOffline) {
      isPointFeature ? this.updateLocationInfo(location) : this.hideSatellite();
    }
    this.changeDetectionRef.detectChanges();
  }

  private fetchImageAnnotationCounts(images: Image[]): Observable<Map<string, number>> {
    const annotations = images.length
      ? this.photosService.getAnnotatedImagesByIds(images.map((image) => image.id))
      : of([]);

    return annotations.pipe(
      map((annotatedImages): Map<string, number> => {
        const annotationCountByImageId = new Map();
        for (const annotatedImage of annotatedImages) {
          annotationCountByImageId.set(annotatedImage.imageId, annotatedImage.annotations.length);
        }
        return annotationCountByImageId;
      }),
    );
  }

  /**
   * Retrieves feature by ID from backend or, if offline mode is on, from
   * offline assets store.
   */
  private getFeature(layerId: string, featureId: string): Observable<Feature | null> {
    // return this.isOffline
    //   ? this.getOfflineFeature(featureId)
    return this.featuresService.getFeature(layerId, featureId, false);
  }

  private setSolarInsights() {
    if (!this.layerInfo.id) {
      return;
    }
    this.showSolarInsights =
      this.configService.solarEnabled && this.layerInfo.name === CIRCUITS_LAYER_NAME;
  }

  private setActions() {
    // Allow image uploads for assets in offline mode.
    this.uploadsEnabled =
      (this.isOffline && this.layerInfo.id === ASSETS_LAYER_ID) ||
      this.layersService.getLayerType(this.layerInfo.id) === Layer_LayerType.ASSETS;
    // Disable tags and edits in offline mode.
    this.tagsEnabled = this.shouldEnableTags();
    this.editsEnabled = this.shouldEnableEdits();
    this.isInspectionStatusVisible = this.shouldEnableInspectionStatus();
  }

  private setHeaderImage(feature: Feature | null) {
    const headerImage = feature?.headerImage;
    if (!headerImage) {
      this.image = null;
      return;
    }
    if (isGCSImageUrl(headerImage.url)) {
      convertGCSImageURL(headerImage.url)
        .pipe(takeUntil(this.destroyed))
        .subscribe({
          next: (url: string) => {
            this.image = ensureURL(
              new Image({
                id: headerImage.id,
                url: url,
                exifMetadata: headerImage.exifMetadata,
              }),
            );
          },
          error: (error) => {
            throw new Error(error);
          },
        });
    } else {
      this.image = ensureURL(
        new Image({
          id: headerImage.id,
          url: headerImage.url,
          exifMetadata: headerImage.exifMetadata,
        }),
      );
    }
  }

  private getFeatureLocation(feature: Feature | null): google.maps.LatLngLiteral | null {
    const point = feature?.geometry?.geometry?.value as Point;
    if (!point?.location) {
      return null;
    }
    const {latitude, longitude} = point.location;
    return {
      lat: latitude,
      lng: longitude,
    };
  }
  /**
   * Set location, get street-view data and possibly update map
   * position.
   */
  private updateLocationInfo(location: google.maps.LatLngLiteral | null) {
    if (!location) {
      return;
    }
    if (this.mapService.getShouldRepositionMap()) {
      this.mapService.setMapCenter(new LatLng({latitude: location.lat, longitude: location.lng}));
      this.mapService.setShouldRepositionMap(false);
    }
    this.googleMapsService
      .getStreetViewData(location)
      .subscribe((streetViewResponse: StreetViewResponse | null) => {
        this.satelliteStreetViewTasks.next(() => {
          this.showSatellite(this.heroSatellite?.nativeElement, location);
        });
        if (streetViewResponse) {
          this.initStreetView(streetViewResponse);
        } else {
          // If Street View is not available show satellite in its place.
          this.showSatellite(this.streetViewContainer?.nativeElement, location);
        }
      });
  }

  /**
   * Set up a subscription for streetView and satellite.
   */
  private initSatelliteStreetViewListener() {
    // The functions that were passed to the replay subject that have to do
    // with showing satellite, street view, or updating photo can now be
    // executed because the DOM is ready.
    this.satelliteStreetViewTasks
      .pipe(takeUntil(this.destroyed))
      .subscribe((callback: Function) => {
        callback();
      });
    this.changeDetectionRef.detectChanges();
  }

  private getOfflineStatus() {
    this.networkService
      .getOffline$()
      .pipe(takeUntil(this.destroyed))
      .subscribe((offline: boolean) => {
        this.isOffline = offline;
      });
  }

  private getImageId(): string {
    return this.images[this.currentImageIndex].id;
  }

  private initStreetView(streetViewResponse: StreetViewResponse) {
    if (this.googleMapsService.shouldRenderStreetView(streetViewResponse)) {
      this.satelliteStreetViewTasks.next(() => {
        this.streetViewContainer.nativeElement.style.visibility = 'visible';
        this.googleMapsService.renderStreetView(
          this.streetViewContainer.nativeElement,
          streetViewResponse.data,
          streetViewResponse.location,
          '',
        );
      });
    }
  }

  private hideStreetview() {
    this.satelliteStreetViewTasks.next(() => {
      this.streetViewContainer.nativeElement.style.visibility = 'hidden';
    });
  }

  private hideSatellite() {
    // Hide any satellite that may be in the DOM.
    this.satelliteStreetViewTasks.next(() => {
      this.heroSatellite.nativeElement.style.visibility = 'hidden';
    });
  }

  private showSatellite(container: HTMLElement | undefined, location: google.maps.LatLngLiteral) {
    if (!container) {
      return;
    }
    this.satelliteStreetViewTasks.next(() => {
      container.style.visibility = 'visible';
      this.googleMapsService.renderSatelliteMap(container, location);
    });
  }

  private getLastUpdatedAt(feature: Feature): Date | null {
    if (!feature.updatedAt || !feature.updatedAt!.seconds) {
      return null;
    }
    const updateMS = Number(feature.updatedAt!.seconds) * MS_PER_SECOND;
    return new Date(updateMS);
  }

  private shouldEnableTags(): boolean {
    return (
      (!this.isOffline && this.layerInfo.id === DEFECTS_LAYER_ID) ||
      this.layerInfo.id !== SUNROOF_LAYER_ID
    );
  }

  private shouldEnableEdits(): boolean {
    return (
      !this.isOffline &&
      this.layerInfo.id === DEFECTS_LAYER_ID &&
      this.configService.uploadViewEnabled
    );
  }

  /**
   * Retrieves feature by ID from offline assets store.
   */
  // getOfflineFeature(featureId: string): Observable<Feature | null> {
  //   return this.offlineAssetsService.getAssetByID(featureId).pipe(
  //     first(),
  //     map((asset: OfflineAssetsInfo | null): Feature | null =>
  //       asset ? offlineAssetToFeature(asset) : null,
  //     ),
  //     takeUntil(this.destroyed),
  //   );
  // }
}
